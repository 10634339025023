.filter-apply-button {
    margin-top: 18px !important;
    margin-right: 17px !important;
}

.export-excel-button,
.filter-apply-button {
    width: 140px;
}

.product-class-cell {
    color: white;
    padding: 3px;
    font-weight: bold;
    width: 40px;
    text-align: center;
}
