@import 'src/App/Layout/_layout.scss';
@import 'src/App/MultiTenant/_tenantsList.scss';

.site-container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-areas:
        'nav'
        'main';
    grid-template-rows: 70px 1fr;
    header {
        grid-area: nav;
        background-color: $color-black;
        border-radius: 0;
        svg {
            color: $color-white;
        }

        .dropdownItemWithIcon {
            display: flex;
            flex-direction: row;

            svg {
                margin-right: 0;
                margin-left: auto;
                color: $color-black;
            }

            &:hover {
                svg {
                    color: $color-white;
                }
            }
        }
    }

    .site-content {
        overflow-y: auto;
        grid-area: main;
    }
}

.user-name-section {
    display: flex;
    align-items: center;
    cursor: pointer;
    .user-name-label {
        color: $color-white;
        text-align: right;

        .user-ItemSubText {
            font-size: 12px;
        }

        label {
            cursor: pointer;
            line-height: 18px !important;
        }
    }
}

.class-manager-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 0;
}

.circular-progress-bar-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    svg {
        color: $primaryColor;
    }
}
