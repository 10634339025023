.validation {
    svg {
        margin: 0 !important;
    }
    &-error {
        color: #f44336 !important; //creative-tim color danger
    }
    &-warning {
        color: #ff9800 !important; //creative-tim color warning
    }
    &-success {
        color: #4caf50 !important; //creative-tim color success
    }
}
