@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .attachment-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: auto !important;
        width: auto !important;
    }
}
