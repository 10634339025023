.main-card {
    padding: 25px;
    margin: 0 !important;

    .card-title {
        color: gray;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        margin: 10px 0;
    }
}

@media (max-width: 1280px) {
    .fieldset {
        margin-top: 30px;
        float: left !important;
    }
}

// Table setting
.fieldset {
    padding-bottom: 10px;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 1px;
    max-width: 450px;
    width: 100%;
    float: right;

    .row {
        display: flex;
        align-items: center;
        margin: 0;
        width: 100%;

        label {
            font-size: 15px;
        }

        .toggle {
            float: right;
        }
    }

    .label {
        border: 5px solid #e91e63;
        background: #e91e63;
        border-radius: 5px;
        color: white;
        padding: 0 5px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 400;
    }
}

// Product search
.subtitle-title {
    color: gray;
    font-size: 20px;
    margin-bottom: 0px;
    font-size: 20px;
}

.form-group-custom {
    > div {
        display: inline-flex;
        max-width: 300px;
    }

    .search-icon {
        margin-right: 10px;
    }
}

// Table
.table-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0 0 0;

    > h3 {
        color: gray;
        font-size: 20px;
        margin: 0px;
        font-size: 20px;
        width: 50%;
    }

    > div {
        width: 50%;
        display: flex;
        flex-flow: row-reverse;
    }
}

.ReactTable {
    font-size: 15px;

    .rt-th {
        font-weight: 600 !important;
    }

    .rt-thead .rt-th:last-child {
        text-align: left;
    }

    .edit-cell {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            color: gray;
            margin-left: 5px;
        }
    }
}
