@import 'src/Shared/Styles/_variables.scss';
@import 'src/Shared/Styles/_colors.scss';
@import 'src/App/_styles.scss';
@import 'src/CreateProduct/_styles.scss';
@import 'src/ConfigureAvailability/_styles.scss';
@import 'src/PriceMaintenance/View/styles';
@import 'src/PriceMaintenance/Update/styles';
@import 'src/ie11Styles.scss';

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
    overflow: hidden;
    background-color: #eeeeee;
    color: #3c4858;
    font-size: 14px;
}

html,
body {
    margin: 0;
    height: 100%;
}
body {
    height: 100%;
}

#root {
    height: 100%;
}

footer {
    padding: 0px !important;
}

.container {
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.card {
    .card-icon {
        svg {
            color: $color-black;
        }
    }

    .card-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .card-icon {
            margin-top: -18px;
        }

        .card-header-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 15px;
            flex-wrap: wrap;
            flex-grow: 1;

            > button {
                margin-left: 10px;
            }
        }

        .card-title {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 25px;
            color: #3c4858;
            margin-top: 0px;
            margin-bottom: 0px;
            min-height: auto;
            font-weight: 300;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            text-decoration: none;
        }
    }
}

.react-table {
    .rt-tr {
        > div {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start; /* horizontally align items in left */
            align-items: center; /* vertically align items in center */
        }
    }

  .rt-thead {
    .rt-th {
      font-weight: 500 !important;
      text-align: left !important;
    }
    .rt-th.-cursor-pointer {
      > div:first-of-type:after {
        top: 5px !important;
        right: 0 !important;
        background-repeat: no-repeat;
        content: url("./Shared/Assets/sort-up-arrow.svg") !important;
      }
    }

    .rt-th.-cursor-pointer.-sort-asc {
      > div:first-of-type:after {
        top: 5px !important;
        right: 0 !important;
        content: url("./Shared/Assets/sort-up-arrow.svg") !important;
      }
    }

    .rt-th.-cursor-pointer.-sort-desc {
      > div:first-of-type:after {
        top: 5px !important;
        right: 0 !important;
        content: url("./Shared/Assets/sort-down-arrow.svg") !important;
      }
    }
  }
}
