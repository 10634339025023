// /************* Vulcan Color Palette *****************/
// $vulcan-yellow-109C: rgb(255, 204, 0);   // #ffcc00
// $vulcan-yellow-102C: rgb(255, 237, 0);   // #ffed00
// $vulcan-yellow-12BC: rgb(255, 216, 81);  // #ffd750
$vulcan-yellow-1235C: rgb(248, 172, 0); // #f8ac00
// $vulcan-yellow-158C: rgb(229, 109, 42);  // #e56d29

// $vulcan-blue-289:  rgb(30, 38, 74);      // #1d2549
$vulcan-blue-2147: rgb(40, 54, 123); // #28367a
$vulcan-blue-299: rgb(71, 159, 210); // #479fd1
// $vulcan-blue-3005: rgb(0, 124, 190);     // #007cbd
// $vulcan-blue-7700: rgb(0, 87, 121);      // #005679
// /****************************************************/

/************* Other Colors *************************/
$color-black: #000000;
$color-grey: #999999;
// $color-light-grey: #d3d3d3;
$color-white: #ffffff;
$color-off-white: #ebecf0;
$color-red: #d50000;
// $color-green: green;
/****************************************************/

/************ Theme Colors ******************/
$primaryColor: $vulcan-blue-2147;
$successColor: $vulcan-blue-299;
$warningColor: $color-red;
/****************************************************/
